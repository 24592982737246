import React, { useEffect, useState } from "react";
import MatchingPlayer from "./MatchingPlayer";
import { UserDetailsKey, getDataFromStore } from "../utils/DataStore";

const Matching = ({ entryFee, playerCount, tableModel, unJoinTemplatePool }) => {
    const [userDetail] = useState(getDataFromStore(UserDetailsKey));
    const [matchingPlayerList, setMatchingPlayerList] = useState([]);
    useEffect(() => {
        let players = [];
        if (tableModel?.chairs) {
            tableModel.chairs.map((chair, index) => {
                if (chair.playerModel !== null && chair.playerModel !== undefined) {
                    if (chair.playerModel.playerId !== userDetail.playerId) {
                        players.push(chair.playerModel);
                    }
                }
            });
        }
        setMatchingPlayerList(players);
    }, [tableModel]);

    return (
        <>
            <div className="matching-body">
                <div>
                    <div className="py-2">
                        <span className="back-btn" onClick={() => unJoinTemplatePool()}>
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div className="text-center">
                        <img src={`/assets/img/asset_${process.env.REACT_APP_GAME_ASSET}/matching.png`} alt="" style={{ width: "150px", marginTop: "-35px" }} />
                        {/* <h5 className=' font-size-24 text-white'>Matching</h5> */}
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center" style={{ flex: "1" }}>
                    <div className="matching-player">
                        {Array.from(Array(playerCount - 1), (_, i) => (
                            <div key={i} className={`player-${i + 1} px-2 ${matchingPlayerList.length > 0 && "PlayerActive"}`}>
                                {matchingPlayerList.length === 0 ? (
                                    <MatchingPlayer />
                                ) : (
                                    <MatchingPlayer playreAvatar={matchingPlayerList[i].avatar} playerName={matchingPlayerList[i].playerName} entryFee={entryFee} />
                                )}
                            </div>
                        ))}
                        <div className="player-4 px-2 PlayerActive">
                            <MatchingPlayer playreAvatar={userDetail.photoImageUrl} playerName={userDetail.playerName} entryFee={entryFee} />
                        </div>
                    </div>
                </div>
                <div className="text-center text-white pb-3 pt-5">
                    <h3>The Game Will Start Shortly....</h3>
                    {/* <h5 className="mt-3">
                        Entry fee will be refunded if all players
                        <br /> do not join the game
                    </h5> */}
                </div>
            </div>
        </>
    );
};

export default Matching;
