import React from 'react';

const AvatarImage = ({ imageUrl }) => {
    return (
        <div className="user-profile">
            <img src={imageUrl ? imageUrl : "assets/img/game_play/default-avatar.png"} alt="user" />
        </div>
    );
}

export default AvatarImage;