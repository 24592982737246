import React, { useEffect, useState } from "react";
import $ from "jquery";
import { BottomPath, Home, LeftPath, RightPath, TopPath } from "./RegularPath";
import Chair from "./Chair";
import { Color } from "../utils/Constants";
import Matching from "./Matching";
import { AccessTokenKey, TableIdKey, TemplateIdKey, UserDetailsKey, deleteDataFromStore, getDataFromStore, setDataInStore } from "../utils/DataStore";
import { getNumberSuffixes, insertSpaceInKeyBeforeCapitalLatter } from "../utils/TextUtils";
import Spin from "./Spin";
import { getTimeStringBySeconds } from "../utils/DateTimeUtils";
import Footer from "./Footer";
import Result from "./Result";
import {
    ClientId,
    MqAmqpPORT,
    MqEXCHANGE,
    MqHOST,
    MqPASSWORD,
    MqStompPORT,
    MqUSERNAME,
    MqVirtualHOST,
    MqWSS,
    MqWebPORT,
    ServerDOMAIN,
    ServerHTTPS,
    ServerPORT,
    ServerSERVICE,
    ServerWSS,
} from "../api/ApiConst";
import StompClient from "../utils/StompClient";
import { useNavigate } from "react-router-dom";
import AudioUtils from "../utils/AudioUtils";
import ModalBox from "./common/Modal";
import md5 from "md5";
import { RightAnswerIcon, WrongAnswerIcon } from "./common/SvgComponents";

var stepMovingInterval = 150;
var stompClient = null;
let globalResponse = null;
const Board = () => {
    const navigate = useNavigate();
    const audioUtils = new AudioUtils();
    const [showBackBtnModal, setShowBackBtnModal] = useState(false);
    const [dataLoadCount, setDataLoadCount] = useState(0);
    const [userDetail, setUserDetail] = useState(getDataFromStore(UserDetailsKey));
    const [accessToken, setAccessToken] = useState(getDataFromStore(AccessTokenKey));
    const [showRulesModal, setShowRulesModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [cancellableBtn, setCancellableBtn] = useState(true);
    const [tableConfiguration, setTableConfiguration] = useState({
        server: {
            https: ServerHTTPS,
            wss: ServerWSS,
            domain: ServerDOMAIN,
            port: ServerPORT,
            service: ServerSERVICE,
        },
        mq: {
            wss: MqWSS,
            host: MqHOST,
            amqpPort: MqAmqpPORT,
            webProt: MqWebPORT,
            stompProt: MqStompPORT,
            virtualHost: MqVirtualHOST,
            userName: MqUSERNAME,
            password: MqPASSWORD,
            exchange: MqEXCHANGE,
        },
        clientId: ClientId,
    });
    const [enableRollDiceAction, setEnableRollDiceAction] = useState(false);
    const [enablePlayTokenAction, setEnablePlayTokenAction] = useState(false);
    const [whoHasTurnChairIndex, setWhoHasTurnChairIndex] = useState(-1);
    let [responsePacket, setResponsePacket] = useState(null);
    const [tableModel, setTableModel] = useState(null);
    const [chairIndex, setChairIndex] = useState([0, 1, 2, 3]);

    const [showCorrectAnsAni, setShowCorrectAnsAni] = useState(false);
    const [tokenSteps, setTokenSteps] = useState(null);

    useEffect(() => {
        if (whoHasTurnChairIndex > -1) {
            let myChairIndex = getMyChairId();
            window._showDefaultDice();
            window._showChairDice(myChairIndex, whoHasTurnChairIndex);
        }
    }, [whoHasTurnChairIndex]);

    useEffect(() => {
        if (responsePacket !== null && responsePacket !== undefined) {
            handleResponsePacketUpdate();
        }
    }, [responsePacket]);

    useEffect(() => {
        if (tableModel !== null && tableModel !== undefined) {
            handleTableUpdate();
        }
    }, [tableModel]);

    const initGame = () => {
        const templateId = getDataFromStore(TemplateIdKey);
        const tableId = getDataFromStore(TableIdKey);

        if (tableId) {
            setTableConfiguration((pre) => ({
                ...pre,
                tableId: tableId,
            }));
            setShowBackBtnModal(false);
        }
        if (templateId) {
            setTableConfiguration((pre) => ({
                ...pre,
                templateId: templateId,
            }));
            setShowBackBtnModal(false);
        }
    };

    useEffect(() => {
        initGame();
        window.parent.postMessage("Gameplay", "*");
        return () => {
            if (stompClient === null || stompClient === undefined) {
                return;
            }
            stompClient.disconnect(() => {
                deleteDataFromStore(TemplateIdKey);
                deleteDataFromStore(TableIdKey);
            });
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            initSocketClient();
        }, 0);
    }, [tableConfiguration]);

    const initSocketClient = () => {
        let userDetails = getDataFromStore(UserDetailsKey);
        deleteDataFromStore(TemplateIdKey);
        deleteDataFromStore(TableIdKey);
        var playerQueue = userDetails.authorization;
        if (playerQueue.length > 200) {
            playerQueue = md5(playerQueue);
        }
        if (tableConfiguration.tableId) {
            setDataInStore(TableIdKey, tableConfiguration.tableId);
            var tempStompClient = new StompClient({
                host: `${tableConfiguration.mq.wss}://${tableConfiguration.mq.host}:${tableConfiguration.mq.stompProt}/ws`,
                username: tableConfiguration.mq.userName,
                password: tableConfiguration.mq.password,
                exchange: tableConfiguration.mq.exchange,
                destinationList: [`/exchange/${MqEXCHANGE}_${tableConfiguration.tableId}`, `/queue/${playerQueue}`],
                onConnectCallBack: () => {
                    tempStompClient.getTableDetailAction(tableConfiguration.clientId, accessToken, tableConfiguration.tableId);
                },
                onMessageReceivedCallBack: (res) => {
                    onTableMessageReceived(res);
                },
                onDisconnect: () => {
                    setShowBackBtnModal(true);
                },
            });
            tempStompClient.initSocket();
            stompClient = tempStompClient;
        } else if (tableConfiguration.templateId) {
            setDataInStore(TemplateIdKey, tableConfiguration.templateId);
            var tempStompClient = new StompClient({
                host: `${tableConfiguration.mq.wss}://${tableConfiguration.mq.host}:${tableConfiguration.mq.stompProt}/ws`,
                username: tableConfiguration.mq.userName,
                password: tableConfiguration.mq.password,
                exchange: tableConfiguration.mq.exchange,
                destinationList: [`/queue/${playerQueue}`],
                onConnectCallBack: () => {
                    tempStompClient.joinTemplatePoolAction(tableConfiguration.clientId, accessToken, tableConfiguration.templateId);
                },
                onMessageReceivedCallBack: (res) => {
                    if (!res.responsePacket) {
                        return;
                    }
                    if (!res.success) {
                        if (res.errorCode === 98) {
                            setErrorMessage(res.message);
                            setShowErrorGameModal(true);
                            setCancellableBtn(false);
                        }
                        return;
                    }
                    if (res.responsePacket.templateId !== tableConfiguration.templateId) {
                        return;
                    }

                    if (res.action === "Ping") {
                        stompClient.pongAction(tableConfiguration.clientId, accessToken, tableConfiguration.templateId);
                        return;
                    } else if (res.action === "UnJoinTemplatePool") {
                        goBackToDashBoard();
                        return;
                    } else if (res.action === "GetTableDetails") {
                        stompClient.disconnect(() => {
                            setTableConfiguration((pre) => ({
                                ...pre,
                                tableId: res.responsePacket.tableId,
                            }));
                        });
                        return;
                    }
                    setResponsePacket(res);
                },
                onDisconnect: () => {
                    setShowBackBtnModal(true);
                },
            });
            tempStompClient.initSocket();
            stompClient = tempStompClient;
        }
    };

    window._onTableMessageReceived = (res) => {
        onTableMessageReceived(res)
    }
    const onTableMessageReceived = (res) => {
        if (!res.responsePacket) {
            return;
        }
        if (!res.success) {
            return;
        }
        if (res.responsePacket.tableId !== tableConfiguration.tableId) {
            return;
        }
        if (res.action === "WaitingForPlayer") {
            return;
        }
        if (res.action === "MessageSkipTurn" || res.action === "Message3SixInARow" || res.action === "InfoMessage") {
            setPrimaryMessagePacket(res);
            if (window.globalTimeOut !== null) {
                clearTimeout(window.globalTimeOut);
            }
            window.globalTimeOut = setTimeout(() => {
                setPrimaryMessagePacket(null);
            }, 3000);
            return;
        } else if (res.action === "LeaveTable") {
            goBackToDashBoard();
            return;
        } else if (res.action === "TokenPlayed") {
            const questionAnswerPacket = res.responsePacket.tableModel.questionAnswer
            if (questionAnswerPacket && questionAnswerPacket.correctAnswer) {
                setShowCorrectAnsAni(true);
                if (questionAnswerPacket.answerIsCorrect) {
                    setTokenSteps(6);
                } else {
                    setTokenSteps(-3);
                }
                setTimeout(() => {
                    setShowCorrectAnsAni(false);
                    setTokenSteps(null);
                }, 3000);
            }
        } else if (res.action === "DiceTurn") {
            for (let k = 0; k < res.responsePacket.tableModel.chairs.length; k++) {
                if (res.responsePacket.tableModel.chairs[k].playerStatus === "DiceTurn" &&
                    res.responsePacket.tableModel.chairs[k].playerModel.playerId === userDetail.playerId) {
                    // Roll the dice
                    let messagePacket = '{"message":"Roll the dice.","responsePacket":{"message":"Roll the dice."},"action":"InfoMessage"}';
                    setPrimaryMessagePacket(JSON.parse(messagePacket));
                    if (window.globalTimeOut !== null) {
                        clearTimeout(window.globalTimeOut);
                    }
                    window.globalTimeOut = setTimeout(() => {
                        setPrimaryMessagePacket(null);
                    }, 3000);
                }
            }
        } else if (res.action === "TokenTurn") {
            for (let k = 0; k < res.responsePacket.tableModel.chairs.length; k++) {
                if (res.responsePacket.tableModel.chairs[k].playerStatus === "TokenTurn" &&
                    res.responsePacket.tableModel.chairs[k].playerModel.playerId === userDetail.playerId) {
                    // Play the token
                    let messagePacket = '{"message":"Play the token.","responsePacket":{"message":"Play the token."},"action":"InfoMessage"}';
                    setPrimaryMessagePacket(JSON.parse(messagePacket));
                    if (window.globalTimeOut !== null) {
                        clearTimeout(window.globalTimeOut);
                    }
                    window.globalTimeOut = setTimeout(() => {
                        setPrimaryMessagePacket(null);
                    }, 3000);
                }
            }
        }
        globalResponse = { ...res };
        setResponsePacket({ ...globalResponse });
    }

    const goBackToDashBoard = () => {
        deleteDataFromStore(TemplateIdKey);
        deleteDataFromStore(TableIdKey);
        globalResponse = null;
        if (stompClient.isConnected()) {
            stompClient.disconnect(() => {
                setTableConfiguration((pre) => ({
                    ...pre,
                    tableId: "",
                    templateId: "",
                }));
                navigate(`/`);
            });
        } else {
            navigate(`/`);
        }
    };

    const handleResponsePacketUpdate = () => {
        if (!globalResponse) {
            return;
        }
        if (!globalResponse.responsePacket) {
            return;
        }
        // this is not current table.
        $(".chair").removeClass("ActiveChair");
        $(".dice").removeClass("ActiveDice");
        $(".token").removeClass("ActiveToken");
        $(".token").removeClass("ActiveTokenAnimation");
        $("#footer-dice").removeClass("DiceActive");
        setEnableRollDiceAction(false);
        setEnablePlayTokenAction(false);
        if (globalResponse.responsePacket.tableModel) {
            let tempTableModel = globalResponse.responsePacket.tableModel;
            if (dataLoadCount === 0) {
                tempTableModel["tokenMovements"] = [];
            }
            setDataLoadCount(dataLoadCount + 1);
            setTableModel(tempTableModel);
            if (globalResponse.action === "DiceRolled") {
                audioUtils.playAudio("ROLL_DICE");
                for (let k = 0; k < globalResponse.responsePacket.tableModel.chairs.length; k++) {
                    if (globalResponse.responsePacket.tableModel.chairs[k].playerStatus === "DiceTurn") {
                        try {
                            window._rollDice(k, globalResponse.responsePacket.tableModel.diceNumber);
                            if (globalResponse.responsePacket.tableModel.chairs[k].playerModel.playerId === userDetail.playerId) {
                                window._rollMyDice(globalResponse.responsePacket.tableModel.diceNumber);
                            }
                        } catch (e) {
                        }
                    }
                }
            } else if (globalResponse.action === "TokenPlayed") {
                window._tokenScoreRemove();
            }
        }
    };

    const handleTableUpdate = () => {
        if (tableModel === null || tableModel === undefined) {
            return;
        }
        if (tableModel.tableConfig.limit === "TimeLimit") {
            setRemainingValue(tableModel.tableConfig.limitValue - tableModel.completedTimeInSeconds);
        } else if (tableModel.tableConfig.limit === "TurnLimit") {
            setRemainingValue(tableModel.tableConfig.limitValue - tableModel.roundCompleted);
        } else {
            setRemainingValue(0);
        }

        let myChairIndex = getMyChairId();
        //// TO Switch Position of Chair Where My Chair is on bottom side.
        if (myChairIndex > -1) {
            let updatedChairIndex = [];
            for (let cIndex = myChairIndex; cIndex < tableModel.chairs.length; cIndex++) {
                updatedChairIndex.push(tableModel.chairs[cIndex].index);
            }
            for (let cIndex = 0; cIndex < myChairIndex; cIndex++) {
                updatedChairIndex.push(tableModel.chairs[cIndex].index);
            }
            setChairIndex(updatedChairIndex);
            drawQuestionMarkOnBoard(updatedChairIndex);
        }

        if (tableModel.tableStatus === "None") {
        } else if (tableModel.tableStatus === "WaitingForPlayer") {
        } else if (tableModel.tableStatus === "WaitingForGame") {
        } else if (tableModel.tableStatus === "Turn") {
            for (let k = 0; k < tableModel.chairs.length; k++) {
                if (tableModel.chairs[k].playerStatus === "DiceTurn" || tableModel.chairs[k].playerStatus === "TokenTurn") {
                    setWhoHasTurnChairIndex(k);
                    $(".chair_" + k).addClass("ActiveChair");
                    if (tableModel.chairs[k].playerStatus === "DiceTurn") {
                        $(".dice_" + k).addClass("ActiveDice");
                        if (tableModel.chairs[k].playerModel.playerId === userDetail.playerId) {
                            $("#footer-dice").addClass("DiceActive");
                            setEnableRollDiceAction(true);
                            if (responsePacket?.action === "DiceTurn") {
                                audioUtils.playAudio("MY_TURN");
                            }
                        }
                    } else if (tableModel.chairs[k].playerStatus === "TokenTurn") {
                        for (let j = 0; j < tableModel.chairs[k].tokenList.length; j++) {
                            let remainingSteps = 56 - tableModel.chairs[k].tokenList[j].tokenPosition;
                            if (remainingSteps === 57) {
                                if (tableModel.tableConfig.startBySixOnly && tableModel.diceNumber === 6) {
                                    $(`.token_${k}_${j}`).addClass("ActiveToken");
                                    $(`.token_${k}_${j}`).addClass("ActiveTokenAnimation");
                                }
                            } else if (remainingSteps >= tableModel.diceNumber) {
                                $(`.token_${k}_${j}`).addClass("ActiveToken");
                                $(`.token_${k}_${j}`).addClass("ActiveTokenAnimation");
                            }
                        }
                        // $(".token_" + k).addClass("ActiveToken");
                        if (myChairIndex === k) {
                            setEnablePlayTokenAction(true);
                        }
                    }
                }
            }
        } else if (tableModel.tableStatus === "Result") {
            setTimeout(() => {
                window.close();
            }, 5000);
        }
    };

    const getMyChairId = () => {
        for (let i = 0; i < tableModel?.chairs?.length; i++) {
            if (tableModel.chairs[i].playerModel !== null && tableModel.chairs[i].playerModel !== undefined) {
                if (tableModel.chairs[i].playerModel.playerId === userDetail.playerId) {
                    return i;
                }
            }
        }
        return -1;
    };

    const [remainingValue, setRemainingValue] = useState(0);
    useEffect(() => {
        let timeOut = null;
        if (remainingValue > 0 && tableModel?.tableConfig?.limit === "TimeLimit") {
            timeOut = setTimeout(() => {
                setRemainingValue(remainingValue - 1);
            }, 1000);
        }
        return () => {
            if (timeOut !== null) {
                clearTimeout(timeOut);
            }
        };
    }, [remainingValue]);

    const getRemainingValueView = () => {
        if (tableModel?.tableConfig?.limit === "TimeLimit") {
            // Get Time View
            return (
                <h4 className="text-light-green mb-0 fw-bold ">
                    <b>{getTimeStringBySeconds(remainingValue)}</b>
                </h4>
            );
        } else if (tableModel?.tableConfig?.limit === "TurnLimit") {
            return (
                <h4 className="text-light-green mb-0 fw-bold ">
                    <b>{remainingValue}</b>
                </h4>
            );
        }
        return <>----</>;
    };

    const [tokenId, setTokenId] = useState("");
    const [tokenList, setTokenList] = useState([]);
    const [showMultiSelectTokenDiv, setShowMultiSelectTokenDiv] = useState(false);

    useEffect(() => {
        if (tokenId === null || tokenId === undefined || tokenId === "") {
            return;
        }
        $(".TokenTurnModal")
            .css("position", "fixed")
            .animate(
                {
                    top: $("#" + tokenId).offset().top - 82,
                    left: $("#" + tokenId).offset().left - 63,
                },
                0
            );
    }, [tokenId]);

    const hideMultiSelectTokenDiv = () => {
        setShowMultiSelectTokenDiv(false);
        setTokenList([]);
        setTokenId("");
    };

    const getMultiSelectToken = () => {
        return !showMultiSelectTokenDiv ? (
            <></>
        ) : (
            <div className="TokenTurnModal">
                <div className="tokenTurnContent">
                    <h5 className="text-white mb-2 text-center">Select Token</h5>
                    <button
                        className="close"
                        onClick={() => {
                            hideMultiSelectTokenDiv();
                        }}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                    <div className="tokenTurnBody">
                        {tokenList.map((token, index) => {
                            return (
                                <span
                                    className="tokenTurnBox"
                                    key={`selectToken-${index}`}
                                    onClick={() => {
                                        $(".token").removeClass("ActiveTokenAnimation");
                                        stompClient.playTokenAction(tableConfiguration.clientId, accessToken, tableConfiguration.tableId, token.tokenIndex);
                                        hideMultiSelectTokenDiv();
                                    }}
                                >
                                    <span className="token-div">
                                        <img src={`assets/img/game_play/token_green.png`} alt="Token" className="img2" />
                                    </span>
                                    <span className="tokenTurnScore">{token.tokenScore}</span>
                                </span>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const drawQuestionMarkOnBoard = (updatedChairIndex) => {
        document.querySelectorAll(`.questionMark`).forEach((item) => {
            item.classList.add('d-none');
        });
        let tokenColor = "";
        if (updatedChairIndex[0] === 0) {
            tokenColor = "g";
        } else if (updatedChairIndex[1] === 0) {
            tokenColor = "y";
        } else if (updatedChairIndex[2] === 0) {
            tokenColor = "b";
        } else if (updatedChairIndex[3] === 0) {
            tokenColor = "r";
        }
        tableModel?.tableConfig?.questionPositions?.map((position) => {
            var pBox = tokenColor + "-" + String(position).padStart(2, "0");
            document.querySelector(`.${pBox} .questionMark`).classList.remove('d-none');
        })
    }

    const onTokenClick = (chairIndex, color, tokenIndex, tokenPosition) => {
        let myChairId = getMyChairId();
        let showScore = false;

        if (myChairId === chairIndex) {
            let tokenCount = 0;
            let tokens = [];
            for (let i = 0; i < tableModel.chairs.length; i++) {
                if (i === chairIndex) {
                    for (let j = 0; j < tableModel.chairs[i].tokenList.length; j++) {
                        if (tableModel.chairs[i].tokenList[j].tokenPosition === tokenPosition) {
                            tokens.push(tableModel.chairs[i].tokenList[j]);
                            tokenCount++;
                        }
                    }
                }
            }
            if (tokenPosition == -1) {
                if (enablePlayTokenAction) {
                    $(".token").removeClass("ActiveTokenAnimation");
                    stompClient.playTokenAction(tableConfiguration.clientId, accessToken, tableConfiguration.tableId, tokenIndex);
                }
            } else if (tokenCount === 0) {
                showScore = true;
            } else if (tokenCount === 1) {
                if (enablePlayTokenAction) {
                    $(".token").removeClass("ActiveTokenAnimation");
                    stompClient.playTokenAction(tableConfiguration.clientId, accessToken, tableConfiguration.tableId, tokenIndex);
                }
            } else if (tokenCount > 1) {
                // Open Token Selector
                if (enablePlayTokenAction) {
                    setTokenId(`token_${color}_${tokenIndex}`);
                    setTokenList(tokens);
                    setShowMultiSelectTokenDiv(true);
                    // openMultiTokenSelectorModal(color, tokenIndex, tokens);
                }
            }
        } else {
            showScore = true;
        }
        if (!enablePlayTokenAction) {
            showScore = true;
        }
        if (showScore) {
            $(`#token_${color}_${tokenIndex}`).closest(".boxes").toggleClass("tokenScoreToggle");
        }
    };

    const getMatchingView = () => {
        let showMatchingView = false;
        let entryFee = 0;
        let playerCount = 2;
        if (responsePacket?.action === "JoinTemplatePool") {
            showMatchingView = true;
            entryFee = responsePacket.responsePacket.template.betAmount + responsePacket.responsePacket.template.feeAmount;
            playerCount = responsePacket.responsePacket.template.playerCount;
        } else if (tableModel?.tableStatus === "WaitingForPlayer") {
            if (!tableModel.tableConfig) {
                return <></>;
            }
            showMatchingView = true;
            entryFee = tableModel.tableConfig.betAmount + tableModel.tableConfig.feeAmount;
            playerCount = tableModel.tableConfig.playerCount;
        }
        return (
            showMatchingView && (
                <>
                    <Matching
                        entryFee={entryFee}
                        playerCount={playerCount}
                        tableModel={tableModel}
                        unJoinTemplatePool={() => {
                            stompClient.unJoinTemplatePoolAction(tableConfiguration.clientId, accessToken, tableConfiguration.templateId);
                        }}
                    />
                </>
            )
        );
    };

    const getBoardView = () => {
        if (tableModel === null || tableModel === undefined) {
            return <></>;
        }
        let myChairIndex = getMyChairId();
        return (
            <>
                {tableModel?.tableStatus === "WaitingForGame" ? <Spin timer={tableModel.customTimer.countDown} /> : <></>}
                <div className="d-flex h-100 position-fixed start-0 end-0 w-100 gamePlayBg" style={{ flexDirection: "column" }}>
                    <header className="w-100 ">
                        <div className="d-flex justify-content-between">
                            <div className="col-4 p-0">
                                <span className="back-btn" onClick={() => setShowLeaveGameModal(true)} data-toggle="modal" data-target="#leaveGameModal">
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div className="col-4 p-0 text-center">
                                <div className="price-div">
                                    <div className="d-flex align-items-center">
                                        <div className="text-center mr-3">
                                            <img src="/assets/img/game_play/trophy.png" />
                                        </div>
                                        <div className="text-center ml-2">
                                            <div>
                                                <span className="text-white mr-2">Winner</span>
                                                <span className="text-light-green">{tableModel?.gamePoolPrize && tableModel.gamePoolPrize.toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {tableModel?.tableConfig?.everyWinnerWinningPercentage?.length > 1 ? (
                                    <div className="text-center ml-2">
                                        <span className="text-light-green">
                                            {tableModel?.tableConfig.everyWinnerWinningPercentage?.map((percentage, index) => {
                                                return (
                                                    <span key={`wp-` + index}>
                                                        {" "}
                                                        ( {index + 1 + getNumberSuffixes(index + 1) + " : " + ((tableModel?.gamePoolPrize * percentage) / 100.0).toFixed(2)} )
                                                    </span>
                                                );
                                            })}
                                        </span>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className="user-time text-center mt-3">
                                    <h6 className="text-white mb-0 ">{tableModel?.tableConfig?.limit && insertSpaceInKeyBeforeCapitalLatter(tableModel?.tableConfig?.limit)}</h6>
                                    {getRemainingValueView()}
                                </div>
                            </div>
                            <div className="col-4 p-0 text-right">
                                <span className="info-div d-none" onClick={() => setShowRulesModal(true)}>
                                    <img src="/assets/img/game_play/menu_icon.png" style={{ width: 40 }} />
                                </span>
                            </div>
                        </div>
                    </header>
                    <div className="text-white text-center px-2" id="errorLog"></div>
                    <div className=" d-flex align-items-center justify-content-center text-white">
                        {tableModel?.gameSessionId}
                    </div>
                    <div className=" d-flex align-items-center justify-content-center" style={{ flex: "1" }}>
                        <div className="outer flexColumn gap-5 position-relative" id="ludoBoard">
                            <div className="weight-2 flexRow gap-5">
                                <Chair color={Color.YELLOW} index={chairIndex[1]} tableModel={tableModel} onTokenClick={onTokenClick} />
                                <TopPath />
                                <Chair color={Color.BLUE} index={chairIndex[2]} tableModel={tableModel} onTokenClick={onTokenClick} />
                            </div>
                            <div className="weight-1 flexRow gap-5">
                                <LeftPath />
                                <Home />
                                <RightPath />
                            </div>
                            <div className="weight-2 flexRow gap-5">
                                <Chair color={Color.GREEN} index={chairIndex[0]} tableModel={tableModel} onTokenClick={onTokenClick} />
                                <BottomPath />
                                <Chair color={Color.RED} index={chairIndex[3]} tableModel={tableModel} onTokenClick={onTokenClick} />
                            </div>
                            {getMultiSelectToken()}
                        </div>
                    </div>
                    <div className="game-footer w-100 py-2">
                        <Footer
                            tableModel={tableModel}
                            myChairIndex={myChairIndex}
                            onRollTheDice={() => {
                                if (enableRollDiceAction) {
                                    stompClient.rollDiceAction(tableConfiguration.clientId, accessToken, tableConfiguration.tableId);
                                }
                            }}
                        />
                    </div>
                </div>

                {tableModel?.tableStatus === "Result" ? (
                    <Result
                        winners={tableModel?.winnerPlayerList}
                        loggedInPlayerId={userDetail.playerId}
                        leaveTable={() => {
                            goBackToDashBoard();
                        }}
                    />
                ) : (
                    <></>
                )}
            </>
        );
    };

    const [primaryMessagePacket, setPrimaryMessagePacket] = useState(null);
    const getPrimaryMessageDialogView = () => {
        // TODO > This is not working on view port.
        if (primaryMessagePacket === null) {
            return <></>;
        }
        if (!(primaryMessagePacket.action === "MessageSkipTurn" ||
            primaryMessagePacket.action === "Message3SixInARow" ||
            primaryMessagePacket.action === "InfoMessage")) {
            return <></>;
        }
        let imgSrc = "";
        let heading = "";
        let body = "";
        if (primaryMessagePacket.action === "MessageSkipTurn") {
            imgSrc = "assets/img/game_play/broken-heart.png";
            if (primaryMessagePacket.responsePacket.messageSenderId !== userDetail.playerId) {
                heading = primaryMessagePacket.responsePacket.messageSenderName + " missed a turn!";
            } else {
                heading = "You missed a turn!";
                body = "If you miss 3 turns, you will be out of the game.";
            }
        } else if (primaryMessagePacket.action === "Message3SixInARow") {
            imgSrc = "assets/img/game_play/redDice.png";
            heading = "Next Player's Turn.";
            body = "Third 6 in a row. can not be played.";
        } else if (primaryMessagePacket.action === "InfoMessage") {
            body = primaryMessagePacket.responsePacket.message;
        }
        return (
            <>
                <div id="dialogMissTurn" className="fade show d-block turnMissed" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="d-flex align-items-center">
                                    <div className="mr-3">
                                        <img src={imgSrc} alt="" style={{ width: "35px" }} />
                                    </div>
                                    <div>
                                        <h4 className="text-white">{heading}</h4>
                                        {body !== "" && <h6 className="text-white">{body}</h6>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const [showLeaveGameModal, setShowLeaveGameModal] = useState(false);
    const leaveGameDialog = () => {
        // TODO > This is not working on view port.
        return (
            showLeaveGameModal && (
                <>
                    <div id="leaveGameModal" className={`modal fade leaveGameModal show`}>
                        <div className="modal-dialog " role="document" style={{ width: "215px" }}>
                            <div className="modal-content bg-purple border-none w-100 rounded-md">
                                <div className="modal-body text-center">
                                    <img src="assets/img/game_play/alert.png" alt="" style={{ width: "45px" }} />
                                    <h5 className="text-yellow font-size-18 mt-1">ALERT!</h5>
                                    <h6 className="text-white font-size-12 mt-3">If you leave an active game, you will lose the game. Are you sure you want to leave ?</h6>
                                    <div className="d-flex mt-4" style={{ gap: "15px" }}>
                                        <button className="btn custom-purple-btn-sm w-100" onClick={() => setShowLeaveGameModal(false)}>
                                            CANCEL
                                        </button>
                                        <button
                                            className="btn custom-green-btn-sm w-100"
                                            onClick={() => stompClient.leaveTableAction(tableConfiguration.clientId, accessToken, tableConfiguration.tableId)}
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        );
    };

    const selectAnswer = (answer) => {
        stompClient.postAnswerAction(tableConfiguration.clientId, accessToken, tableConfiguration.tableId, answer);
    }

    const drawQuestionAnswerModal = () => {
        if (!(tableModel?.tableStatus === "QuestionTurn" || tableModel?.tableStatus === "AnswerTurn")) {
            return;
        }
        return <>
            <div id="questionAnswerModal" className={`modal fade questionAnswerModal show`} style={{ display: "flex" }}>
                <div className="modal-dialog " role="document" style={{ width: "70%" }}>
                    <div className="modal-content bg-purple border-none w-100 rounded-md">
                        <div className="modal-body text-center">
                            <img src="assets/img/game_play/question_icon.png" alt="" style={{ width: "45px" }} />
                            <h5 className="text-yellow font-size-18 mt-1">
                                Question for
                                {tableModel?.chairs[getMyChairId()].playerStatus === "TokenTurn" ? <> You </> : <> Opponent </>}
                                !
                            </h5>
                            <h6 className="text-white font-size-12 mt-3">{tableModel?.questionAnswer?.question}</h6>
                            {tableModel?.tableStatus === "QuestionTurn" && <>
                                {tableModel?.chairs[getMyChairId()].playerStatus === "TokenTurn" ? <div className="d-flex mt-4" style={{ gap: "15px" }}>
                                    <button className="btn custom-green-btn-sm w-100" onClick={() => selectAnswer(tableModel?.questionAnswer?.optionA)}>
                                        A. {tableModel?.questionAnswer?.optionA}
                                    </button>
                                    <button
                                        className="btn custom-green-btn-sm w-100"
                                        onClick={() => selectAnswer(tableModel?.questionAnswer?.optionB)}
                                    >
                                        B. {tableModel?.questionAnswer?.optionB}
                                    </button>
                                </div> : <span className="text-white text-center">Waiting for answer</span>}
                            </>}

                            {tableModel?.tableStatus === "AnswerTurn" && <>
                                <div className="d-flex mt-4" style={{ gap: "15px" }}>
                                    <button className="btn custom-green-btn-sm w-100 disabled">
                                        A. {tableModel?.questionAnswer?.optionA}
                                    </button>
                                    <button className="btn custom-green-btn-sm w-100 disabled">
                                        B. {tableModel?.questionAnswer?.optionB}
                                    </button>
                                </div>
                                <div className="mt-3 p-2" style={{ backgroundColor: "#4d24b0", borderRadius: "5px" }}>
                                    {/* {false ?  */}
                                    {tableModel?.questionAnswer?.answerIsCorrect ?
                                        <span className="d-flex justify-content-between align-items-center px-2">
                                            <div className="text-white">Right Answer</div>
                                            <div><RightAnswerIcon /></div>
                                        </span> :
                                        <span className="d-flex justify-content-between align-items-center px-2">
                                            <div className="text-white">Wrong Answer</div>
                                            <div><WrongAnswerIcon /></div>
                                        </span>}
                                </div>
                                {tableModel?.questionAnswer?.answerHint && <div className="mt-2 text-white text-center" style={{ gap: "15px" }}>
                                    Answer Suggestion : {tableModel?.questionAnswer?.answerHint}
                                </div>}
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    const [showErrorGameModal, setShowErrorGameModal] = useState(false);

    window._rePositionToken = (chairIndex, color, tokenIndex, tokenPosition) => {
        let tokenId = "token_" + color + "_" + tokenIndex;
        $(".token").removeClass("ActiveTokenAnimation");
        let positionBox = "";
        if (color === Color.YELLOW) {
            positionBox = "y-";
        } else if (color === Color.RED) {
            positionBox = "r-";
        } else if (color === Color.BLUE) {
            positionBox = "b-";
        } else if (color === Color.GREEN) {
            positionBox = "g-";
        }
        positionBox += String(tokenPosition).padStart(2, "0");
        if (tokenPosition === 56) {
            // Inside Center Home
            setTimeout(() => {
                window._moveTokenToHome(tokenId, positionBox, false);
            }, (tableModel.diceNumber + 1) * stepMovingInterval);
        }

        let moveToken = false;
        if ($("#" + tokenId)) {
            moveToken = true;
        }
        if (tableModel.tokenMovements) {
            for (let i = 0; i < tableModel.tokenMovements.length; i++) {
                if (tableModel.tokenMovements[i].tokenPosition <= 0) {
                    // continue;
                    moveToken = false;
                }
                if (tableModel.tokenMovements[i].chairIndex === chairIndex && tableModel.tokenMovements[i].tokenIndex === tokenIndex) {
                    moveToken = false;
                }
            }
        }

        if (moveToken) {
            window._moveTokenTransform(tokenId, positionBox, 0, false);
            setTimeout(() => {
                window._moveTokenTeleport(tokenId, positionBox, false, false);
            }, 10);
        } else {
            let timeout = 0;
            for (let i = 0; i < tableModel.tokenMovements.length; i++) {
                if (tableModel.tokenMovements[i].chairIndex !== chairIndex) {
                    continue;
                }
                if (tableModel.tokenMovements[i].tokenPosition > tableModel.tokenMovements[i].tokenLastPosition) {
                    if (tableModel.tokenMovements[i].tokenPosition === 0) {
                        let pBox = "";
                        if (color === Color.YELLOW) {
                            pBox = "y-";
                        } else if (color === Color.RED) {
                            pBox = "r-";
                        } else if (color === Color.BLUE) {
                            pBox = "b-";
                        } else if (color === Color.GREEN) {
                            pBox = "g-";
                        }
                        pBox += String(0).padStart(2, "0");
                        setTimeout(() => {
                            window._moveTokenTeleport(tokenId, pBox, true, false);
                        }, timeout + 1);
                        continue;
                    }
                    // Move Forward Animation
                    let finalPBox = "";
                    for (let j = tableModel.tokenMovements[i].tokenLastPosition; j <= tableModel.tokenMovements[i].tokenPosition; j++) {
                        let pBox = "";
                        if (color === Color.YELLOW) {
                            pBox = "y-";
                        } else if (color === Color.RED) {
                            pBox = "r-";
                        } else if (color === Color.BLUE) {
                            pBox = "b-";
                        } else if (color === Color.GREEN) {
                            pBox = "g-";
                        }
                        pBox += String(j).padStart(2, "0");

                        if (j === tableModel.tokenMovements[i].tokenLastPosition) {
                            // Assign a fix position again.
                            setTimeout(() => {
                                window._moveTokenTransform(tokenId, pBox, 0, true);
                            }, 0);
                        } else {
                            setTimeout(() => {
                                window._moveTokenTransform(tokenId, pBox, -1, true);
                            }, timeout);
                            timeout += stepMovingInterval;
                        }
                        finalPBox = pBox;
                    }
                    if (finalPBox.length > 0) {
                        setTimeout(() => {
                            window._moveTokenTeleport(tokenId, finalPBox, false, true);
                        }, timeout + 1);
                    }
                } else {
                    // Kill Animation
                    setTimeout(() => {
                        audioUtils.playAudio("KILL_TOKEN");
                        let finalPBox = "";
                        for (let j = tableModel.tokenMovements[i].tokenLastPosition; j >= tableModel.tokenMovements[i].tokenPosition; j--) {
                            let pBox = "";
                            if (color === Color.YELLOW) {
                                pBox = "y-";
                            } else if (color === Color.RED) {
                                pBox = "r-";
                            } else if (color === Color.BLUE) {
                                pBox = "b-";
                            } else if (color === Color.GREEN) {
                                pBox = "g-";
                            }
                            pBox += String(j).padStart(2, "0");
                            if (j === tableModel.tokenMovements[i].tokenLastPosition) {
                                // Assign a fix position again.
                                setTimeout(() => {
                                    window._moveTokenTransform(tokenId, pBox, 0, false);
                                }, 0);
                            } else {
                                setTimeout(() => {
                                    window._moveTokenTransform(tokenId, pBox, 50, false);
                                }, timeout + 50);
                                timeout += 50;
                            }
                            finalPBox = pBox;
                        }
                        setTimeout(() => {
                            window._moveTokenTeleport(tokenId, finalPBox, false, false);
                        }, timeout + stepMovingInterval);
                    }, tableModel.diceNumber * stepMovingInterval);
                }
            }
        }
    };

    window._moveTokenTransform = (tokenId, moveToBoxClass, animationTimeOut, playAudio) => {
        // if (playAudio) {
        //     audioUtils.playAudio("JUMP_TOKEN");
        // }
        // $("#" + tokenId).removeClass("token");
        // // Transform with animation
        // let value = size[0] > 767 ? 6 : 3;
        // $("#" + tokenId).css("position", "fixed").animate({
        //     "top": $("." + moveToBoxClass).offset().top - 0,
        //     "left": $("." + moveToBoxClass).offset().left + value
        // }, animationTimeOut > -1 ? animationTimeOut : 100);
        window._moveTokenTeleport(tokenId, moveToBoxClass, playAudio, false);
    };

    window._moveTokenToHome = (tokenId, tokenContainerParent, playAudio) => {
        // Teleporting token to home.
        window._moveTokenTeleport(tokenId, tokenContainerParent, playAudio, false);
    };

    window._moveTokenTeleport = (tokenId, tokenContainerParent, playAudio, playSafeHouseAudio) => {
        if (playAudio) {
            audioUtils.playAudio("JUMP_TOKEN");
        }
        if (tokenContainerParent.split("-")[1] === "56") {
            audioUtils.playAudio("GO_HOME");
        } else if (
            playSafeHouseAudio &&
            $("." + tokenContainerParent)
                .closest(".boxes")
                .hasClass("safe")
        ) {
            audioUtils.playAudio("SAFE_HOUSE");
        }

        // Teleporting token to Target Parent Container.
        let token = $("#" + tokenId).remove();
        $("." + tokenContainerParent)
            .find(".tokenContainer")
            .append(token);

        setTimeout(() => {
            $("#" + tokenId).addClass("token");
            $("#" + tokenId).css("position", "unset");
            $("#" + tokenId).css("left", "0px");
            $("#" + tokenId).css("top", "0px");
            $(".tokenContainer").each(function () {
                $(this).removeClass("tokenScoreActive");
            });
            $(".img2").each(function () {
                if (!$(this).parent().hasClass("tokenScoreActive")) {
                    $(this).parent().addClass("tokenScoreActive");
                }
            });
        }, 50);

        setTimeout(() => {
            $(".tokenContainer").each(function () {
                var _tokenContainer = this;
                $(_tokenContainer).parent().find(".tokenScoreBox").html("");
                if ($(_tokenContainer).children().length > 0) {
                    $(_tokenContainer)
                        .children()
                        .each(function () {
                            var child = this;
                            var tokenScore = $(child).attr("token_score");
                            var tokenColor = $(child).attr("color");
                            $(_tokenContainer)
                                .parent()
                                .find(".tokenScoreBox")
                                .append('<span class="tokenScore ' + tokenColor + '">' + tokenScore + "</span>");
                        });
                }
            });
            $(".token").removeClass("ActiveToken");
        }, 51);
    };

    const showAnimation = () => {
        return (
            <div className="zoom_ani">
                <span className={`text-content${tokenSteps === 6 ? " text-success" : " text-danger"}`}>{tokenSteps}</span>
            </div>
        )
    }

    return (
        <>
            {getMatchingView()}
            {getBoardView()}
            {getPrimaryMessageDialogView()}
            {leaveGameDialog()}
            {drawQuestionAnswerModal()}
            {showCorrectAnsAni && showAnimation()}
            <ModalBox show={showErrorGameModal} modalClass={"errorGameModal"}>
                <div className="modal-dialog " style={{ width: "215px" }}>
                    <div className="modal-content bg-purple border-none w-100 rounded-md">
                        <div className="modal-body text-center">
                            <div className="modal-header modal-body-header custom-border-light p-0 justify-content-center">
                                {cancellableBtn && (
                                    <button type="button" className="close btn-close top-0" style={{ top: "0" }} data-dismiss="modal" aria-label="Close" onClick={() => setShowRulesModal(false)}>
                                        <span className="cursor-pointer">
                                            <img src="assets/img/game_play/modal-close.png" alt="" style={{ width: 25 }} />
                                        </span>
                                    </button>
                                )}
                                <h5 className="modal-title text-yellow font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle"></h5>
                            </div>
                            <img src="assets/img/game_play/alert.png" alt="" style={{ width: "45px" }} />
                            <h5 className="text-yellow font-size-18 mt-1">ALERT!</h5>
                            <h6 className="text-white font-size-12 mt-3">{errorMessage}</h6>
                            <div className="d-flex mt-4" style={{ gap: "15px" }}>
                                {/* <button className="btn custom-purple-btn-sm w-100" onClick={() => setShowErrorGameModal(false)}>CANCEL</button> */}
                                <button className="btn custom-green-btn-sm w-100" onClick={goBackToDashBoard}>
                                    Go Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={showRulesModal} toggleModel={() => setShowRulesModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-content bg-purple border-none">
                    <div className="modal-body pt-0 customScroll">
                        <div className="modal-header modal-body-header custom-border-light">
                            <button type="button" className="close btn-close" data-dismiss="modal" aria-label="Close" onClick={() => setShowRulesModal(false)}>
                                <span className="cursor-pointer">
                                    <img src="assets/img/game_play/modal-close.png" alt="" style={{ width: 25 }} />
                                </span>
                            </button>
                            <h5 className="modal-title text-yellow font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                RULES
                            </h5>
                        </div>
                        <div
                            className="pt-4 px-2 "
                            style={{
                                height: "370px",
                                overflowX: "hidden",
                                overflowY: "auto",
                            }}
                        >
                            <div className="align-items-center mb-3">
                                <ol className="text-white font-size-14 mb-50">
                                    <li>Each player assigned green color and places their four tokens in their corresponding starting area.</li>
                                    <li>The game proceeds in a clockwise direction.</li>
                                    <li>Players take turns rolling a six-sided die. The number rolled indicates the number of spaces a player's token can move.</li>
                                    <li>A player must roll a 6 to move a token from the starting area onto the board. Once a token is on the board, any number can be used to move it.</li>
                                    <li>Tokens move around the board in a clockwise direction along the outer squares and then up their home column to the center.</li>
                                    <li>If a player rolls a 6, they get an additional turn.</li>
                                    <li>If a player lands on a space occupied by an opponent's token, the opponent's token is sent back to its starting area.</li>
                                    <li>Players cannot capture their own tokens.</li>
                                    <li>Tokens must travel around the entire board before entering the home column. A player must roll the exact number to land a token in the home column.</li>
                                    <li>If a player rolls a 6, they have the option to either move a token from the starting area or advance a token already on the board.</li>
                                    <li>Three 6 in a Row is not allowed, so player will skip the turn.</li>
                                    <li>If player didn't rolled the dice or player token on there turn, will be skip the turn.</li>
                                    <li>If player skiped 3 turns, will be Knocked out.</li>
                                    <li>
                                        <b>No Limit</b> : The first player to move all four tokens into their home column wins the game.
                                    </li>
                                    <li>
                                        <b>Time Limit</b> : Player who has maximum score on end of the time, will be the winner.
                                    </li>
                                    <li>
                                        <b>Turn Limit</b> : Player who has maximum score on end of the turn counts, will be the winner.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox
                show={showBackBtnModal}
                // toggleModel={() => setShowBackBtnModal(false)}
                modalClass={"backBtnModalClass"}
            >
                <div className="modal-content bg-purple border-none">
                    <div className="modal-body pt-0 customScroll text-center mx-auto">
                        <div className="modal-header modal-body-header custom-border-light"></div>
                        <h2 className="text-white ">Unable to connect to the host, please try again</h2>
                        {/* Unable to fetch details, please try again */}
                        <button className="btn custom-green-btn rounded-0 cursor-pointer mt-3" onClick={() => navigate("/")}>
                            Go Back
                        </button>
                    </div>
                </div>
            </ModalBox>
        </>
    );
};

export default Board;
